import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() itemsList: string[];
  @Input() label: string;
  @Input() value: string;
  @Output() changeSelectValue = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
 
  }
  changeSelect(event){
    this.changeSelectValue.emit(event.value);  
  }
}
