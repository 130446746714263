
<div class="table-wrap">
  <div class="thead">
    <div class="tr">
      <div class="th" *ngFor="let item of tableData.headlines; let i = index" >
        {{ item.text }}
        <div class="wrap-arrow" *ngIf="i < tableData.headlines.length-1">
        <img src="../../../assets/images/topArrow.png" alt="top-arrow" (click)="clickArrow('top', item.field)"/>
        <img src="../../../assets/images/bottomArrow.png" alt="bottom-arrow" (click)="clickArrow('bottom', item.field)"/>  
      </div>
      </div>
      
    </div>
  </div>
  <div class="tbody">
    <div
      id="{{ item.id }}"
      class="table-row-wrap"
      *ngFor="let item of tableData.data; let itemIndex = index"
    >
      <div class="tr"  (click)="rowClick(item)">
        <div
          class="td"
          *ngFor="let field of tableData.headlines; let i = index"
        >
          {{ checkFields(i, item, field) }}
          <div class="tooltip-wrap" *ngIf="checkTooltip(item, field)">{{checkFields(i, item, field)}}</div>
        </div>
 
      </div>
    
    </div>
  </div>

</div>
