<div class="page" *ngIf="product">
    <h1><span class="bold">Product Code |</span> {{product.code}}</h1>
    <div class="product-details">
       <ul>
           <li>
               <div class="key">Brand name</div>
               <div class="value">{{product.brand}}</div>
           </li>
           <li>
            <div class="key">Name </div>
            <div class="value">{{product.name}}</div>
        </li>
        <li>
            <div class="key">Product type</div>
            <div class="value">{{product.productType}}</div>
        </li>
        <li>
            <div class="key">Amount </div>
            <div class="value">{{product.amount}}</div>
        </li>
        <li>
            <div class="key">Molecule relation </div>
            <div class="value">{{product.noleculeRelation}}</div>
        </li>
       </ul>
       <ul>
        <li>
            <div class="key">The EC effect </div>
            <div class="value">{{product.ecEffect}}</div>
        </li>
        <li>
         <div class="key">Boron </div>
         <div class="value">{{product.phEffect}}</div>
     </li>
    <li>
         <div class="key">Product solubility</div>
         <div class="value">{{product.productSolubility}}</div>
     </li>
      <li>
         <div class="key">other product families  </div>
         <div class="value">{{product.productFamilies}}</div>
     </li>

    </ul>
    </div>
</div>
<div class="wrap-loader" *ngIf="showLoader">
    <div class="loader-img"></div>
  </div>
  <div class="wrap-popup" *ngIf="showPopup">
    <div class="content">
        <img src="../../../assets/images/x-circle.png" (click)="close()"/>
      {{textError}}
    </div>

</div>