export class QueryField {
    name: string;
    value: string;
    field: string;
  }
  export class Condition {
    name: string;
    value: number;
    operation: Operation;
  }
  export class QueryData {
    queryValues: QueryField[];
    from?: Date;
    to?: Date;
    conditionValues?:Condition[];  
  }
  export enum Operation{
    lt = '<',
    eq = '=',
    gt = '>'
  }