import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() tableData: Table<any>;
  @Output() clickRowEvent = new EventEmitter<any>();
  @Output() sortArray = new EventEmitter<SortAray>();
  constructor() { }

  ngOnInit(): void {
  }

  checkFields(index, item, field) {
    if ( item[field.field] ) {
      return item[field.field];
    } else  {
      return "-";
    } 
  }
  checkTooltip(item, field){
    if ( item[field.field] &&  item[field.field].length > 20) 
    return true;
    return false;
  }
  rowClick(row){
   this.clickRowEvent.emit(row);
  }
  clickArrow(direction, field){
   this.sortArray.emit({direction:direction, field:field})
  }



}
export class SortAray{
direction: string;
field: string;
}
export class Table<T> {
  type: string;
  headlines: Headline[];
  data: T[];
  rowButton: RowButton[];
}

class Headline {
  field: string;
  text: string;
  editable?: boolean;
  type?: string;
  options?: any[];
  minLength?: number;
  maxLength?: number;
  displayingKey?: string;
  defaultValue?: string;
  size?: number;
}
export class RowButton {
  type: string;
  action: string;
  text?: string;
  icon?: string;
}