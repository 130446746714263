<div class="d-flex justify-content-between">
    <div class="d-flex left justify-content-between align-items-end">
        <mat-form-field *ngFor="let queryValue of quertData.queryValues"  >
            <mat-label>{{queryValue.name}}</mat-label>
            <input matInput [placeholder]="queryValue.name" [(ngModel)]="queryValue.value" (ngModelChange)="valuechange()">
          </mat-form-field>
    </div>
    <div class="d-flex right align-items-baseline">
        <!-- <app-datepicker (changeDate)="quertData.from=$event" [dateValue]="quertData.from"></app-datepicker>
       <span>To</span>
        <app-datepicker (changeDate)="quertData.to=$event" [dateValue]="quertData.to"></app-datepicker> -->
        <button class="white-button space" (click)="search()">Search</button>
        <!-- <button class="white-button space" (click)="advancedSearch()">Advanced Search</button> -->
        <div class="clear" (click)="clear()">Clear search</div>
    </div>
</div>
