import { Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
import { SdkService } from 'src/app/services/sdk.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private adalService: AdalService,private sdkService: SdkService
  ) {
    this.adalService.init(environment.adalConfig);
  }
  canActivate(){
    try {
      if (this.adalService) {
        if (this.adalService.userInfo.authenticated) {
          this.sdkService.setToken(this.adalService.userInfo.token)
           return true
        } else {
          this.adalService.login();
          return;
        }
      }
    } catch (err) {
      console.error('[SessionService]', err);
    }
  }


  async logout() {
    try {
      this.adalService.logOut();
    } catch (err) {
      console.error('[SessionService]', '(logout)', err);
    }
  }
}
