import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TableComponent } from './components/table/table.component';
import { QueryResultsComponent } from './screens/query-results/query-results.component';

import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AdvancedQueryComponent } from './screens/advanced-query/advanced-query.component';
import { QueryComponent } from './components/query/query.component';
import { ProductComponent } from './screens/product/product.component';
import {DummySdk} from 'eport-sdk/build/src/DummySdk';
import {MatSelectModule} from '@angular/material/select';
import { SelectComponent } from './components/select/select.component';
import { MatConditionComponent } from './components/mat-condition/mat-condition.component';
import { MenuComponent } from './components/menu/menu.component';
import HttpSdk from 'eport-sdk/build/src/HttpSdk';
import { AdalInterceptor, AdalService } from 'adal-angular4';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PostLoginComponent } from './screens/post-login/post-login.component';
@NgModule({
  declarations: [
    AppComponent,
    TableComponent,
    QueryResultsComponent,
    QueryComponent,
    HeaderComponent,
    DatepickerComponent,
    AdvancedQueryComponent,
    ProductComponent,
    SelectComponent,
    MatConditionComponent,
    MenuComponent,
    PostLoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    HttpClientModule
  ],
  providers: [DummySdk, HttpSdk, AdalService, {provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true } ],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
