import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Input() label: string;
  @Input() dateValue: Date;
  @Output() changeDate = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  orgValueChange(dateEvent){
    this.changeDate.emit(dateEvent.target.value);
   }
}
