import { Injectable } from '@angular/core';
import {DummySdk} from 'eport-sdk/build/src/DummySdk';
import HttpSdk from 'eport-sdk/build/src/HttpSdk';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SdkService {

  constructor(private httpSdk: HttpSdk, private dummySdk: DummySdk,) {
    
   }
   setToken(token: string){
    this.httpSdk.setToken(token);
   }
  async getProducts(){
    if(environment.dummyData){
      console.log('dummy')
      return await this.dummySdk.search();
    } else{
      return await this.httpSdk.search();
    }
 
   }
   async search(searchConfig){
    if(environment.dummyData){
      console.log('dummy')
    return await this.dummySdk.search(searchConfig);
    } else{
    return await this.httpSdk.search(searchConfig);

    }
    }
    async getProductById(id: string){
      if(environment.dummyData){
        console.log('dummy')
      return await this.dummySdk.getProductByCode(id);
      } else{
      return await this.httpSdk.getProductByCode(id);
      }
      }
}
