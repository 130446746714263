import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Operation, QueryData } from '../entities/Query';

@Injectable({
  providedIn: 'root'
})
export class QueryService {
  queryData : QueryData =  {
    queryValues : [
      {name: 'Brand', value: '', field: 'brand'}, 
      {name: 'Product Type', value: '', field: 'product_type'}, 
      {name: 'Amount', value: '', field: 'amount'}, 
      {name: 'Molecule Relation', value: '', field: 'noleculeRelation'}, 
      {name: 'EC Effect', value: '', field: 'ec_fertilizer'}, 
      {name: 'product families', value: '', field: 'product_subtype'}, 
    ],
    from: null,
    to: null,
    conditionValues: [ {name: null, operation: Operation.gt, value: null}, 
      {name: null, operation: Operation.eq, value: null},
      {name: null, operation: Operation.lt, value: null}
    ]
  };
  private _queryData  = new BehaviorSubject<QueryData>(this.queryData);
  public queryData$ = this._queryData.asObservable();

  // private clearEvent = new Subject<boolean>();
  // public clearEvent$ = this.clearEvent.asObservable();

 
  constructor() { }
  resetQuery(){
     this.queryData =  {
      queryValues : [
      {name: 'Brand', value: '', field: 'brand'}, 
      {name: 'Product Type', value: '', field: 'product_type'}, 
      {name: 'Amount', value: '', field: 'amount'}, 
      {name: 'Molecule Relation', value: '', field: 'noleculeRelation'}, 
      {name: 'EC Effect', value: '', field: 'ec_fertilizer'}, 
      {name: 'product families', value: '', field: 'product_subtype'}, 
    ],
    from: null,
    to: null,
    conditionValues: [ {name: null, operation: Operation.gt, value: null}, 
      {name: null, operation: Operation.eq, value: null},
      {name: null, operation: Operation.lt, value: null}
    ]
  };
  this._queryData.next(this.queryData);
  }
  // sendClearEvent(){
  //   this.clearEvent.next(true);
  //   this.resetQuery();
  // }

  setQueryData(queryData: QueryData){
    this._queryData.next(queryData);
  }
}
