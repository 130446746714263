import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostLoginComponent } from './screens/post-login/post-login.component';
import { AdvancedQueryComponent } from './screens/advanced-query/advanced-query.component';
import { ProductComponent } from './screens/product/product.component';
import { QueryResultsComponent } from './screens/query-results/query-results.component';
import { AuthService } from './services/auth/auth.service';


const routes: Routes = [
  {path: '', canActivate: [AuthService], component: QueryResultsComponent},
  {path: 'searchResults', canActivate: [AuthService], component: QueryResultsComponent},
  // {path: 'query', canActivate: [AuthService], component: AdvancedQueryComponent},
  {path: 'product/:id', canActivate: [AuthService], component: ProductComponent},
  {path: 'product', canActivate: [AuthService], component: ProductComponent},
  {path: 'postLogin', component: PostLoginComponent},
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
