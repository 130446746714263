<div class="page">
    <app-query (queryEvent)="createQuery()"></app-query>
    <app-table [tableData]="tableData" (clickRowEvent)="clickRow($event)" *ngIf="tableData.data.length > 0" (sortArray)="sortArray($event)"></app-table>
    <div class="empty-results" *ngIf="showEmptyResult">No Results</div>
    <div class="wrap-loader" *ngIf="showLoader">
        <div class="loader-img"></div>
      </div>

      <div class="wrap-popup" *ngIf="showPopup">
          <div class="content">
            <img src="../../../assets/images/x-circle.png" (click)="close()"/>
            {{textError}}
          </div>
    
      </div>
</div>
