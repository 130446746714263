import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICLProduct } from 'src/app/entities/ICLProduct';
import { SdkService } from 'src/app/services/sdk.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  product: ICLProduct;
  showLoader: boolean;
  showPopup: boolean;
  textError: string;

  constructor( private route: ActivatedRoute, private sdkService: SdkService) { }
  
   async ngOnInit() {
     try{
      this.showLoader = true;
   const productId = this.route.snapshot.queryParams["id"];
   const  res = await this.sdkService.getProductById(productId);
   this.showLoader = false;
   if(res)
   this.product = new ICLProduct(res);
     } catch (err) {
      this.showLoader = false;
      this.showPopup = true;
      this.textError = err;
      console.log('error')
      console.error(err);
    }
  }
  close(){
    this.showPopup = false;
    this.textError = "";
  }
}
