export class ICLProduct {
    brand: string
    code: string
    countries: string[]
    name:string
    productType:string
    amount: string
    noleculeRelation:string
    ecEffect:string
    phEffect:string 
    productSolubility?: string 
    productFamilies?: string 
    constructor(args?: Partial<ICLProduct>) {
        this.brand = args.brand;
        this.code = args.code;
        this.countries = args.countries;
        this.name = args.name;
        this.productType = args.productType;
        this.amount = args.amount;
        this.noleculeRelation = args.noleculeRelation;
        this.ecEffect = args.ecEffect;
        this.phEffect = args.phEffect;
        this.productSolubility = args.productSolubility;
        this.productFamilies = args.productFamilies;

    }
}
