import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscriber } from 'rxjs';
import { QueryData } from 'src/app/entities/Query';
import { QueryService } from 'src/app/services/query.service';

@Component({
  selector: 'app-advanced-query',
  templateUrl: './advanced-query.component.html',
  styleUrls: ['./advanced-query.component.scss']
})
export class AdvancedQueryComponent implements OnInit, OnDestroy {
  selectList = ['aaa', 'bbbb', 'cccc'];
  queryData:QueryData;
  //  ={
  //   queryValues : [
  //     {name: 'Brand', value: '', field: 'brand'}, 
  //     {name: 'Product Type', value: '', field: 'product_type'}, 
  //     {name: 'Amount', value: '', field: 'amount'}, 
  //     {name: 'Molecule Relation', value: '', field: 'noleculeRelation'}, 
  //     {name: 'EC Effect', value: '', field: 'ec_fertilizer'}, 
  //     {name: 'product families', value: '', field: 'product_subtype'}, 
  //   ],
  //   from: null,
  //   to: null,
  //   conditionValues: [ {name: null, operation: Operation.gt, value: null}, 
  //     {name: null, operation: Operation.eq, value: null},
  //     {name: null, operation: Operation.lt, value: null}
  //   ]
  // }
  constructor(private queryService: QueryService, private router: Router) { }
  subscription = new Subscriber()
  ngOnInit(): void {
    this.subscription.add(this.queryService.queryData$.subscribe(quertData => {
      console.log(quertData)
      this.queryData = quertData;
    }))
  //  this.queryService.resetQuery();
  }
  ngOnDestroy(){
    this.subscription.unsubscribe()
  }
  clear(){
    this.queryService.resetQuery()
    // this.queryData.conditionValues.forEach(condition => {
    //   condition.name = null;
    //   condition.value = null;
    // });
    // this.queryService.sendClearEvent();
  }
  updateConditionValue(newCondition, index){
   this.queryData.conditionValues[index]=newCondition;
  }
  search(){
   // this.queryService.queryData.conditionValues = this.queryData.conditionValues;
   this.queryService.setQueryData(this.queryData)
    this.router.navigate(["searchResults"], {
      queryParams: { getData: true },
    });
  }
}
