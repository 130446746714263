import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuIsOpen:boolean;
  title: string;
  userName: string;
  @Output() manageMenuEvent = new EventEmitter<boolean>();
  constructor(private adalService: AdalService) { }

  ngOnInit(): void {
    if(this.adalService.userInfo && this.adalService.userInfo.profile && this.adalService.userInfo.profile.name){
      this.userName = this.adalService.userInfo.profile.name
    } else{
      this.userName = "";
    }
  }
  manageMenu(){
    this.menuIsOpen = !this.menuIsOpen;
    this.manageMenuEvent.emit(this.menuIsOpen);
  }
  async logout() {
    try {
      this.adalService.logOut();
    } catch (err) {
      console.error('[SessionService]', '(logout)', err);
    }
  }

}
