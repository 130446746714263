<div class="page">
    <app-query></app-query>
    <div class="contnet-page">
        <!-- <div class="left">
            <app-select label="Category Name 1" [itemsList]="selectList"></app-select>
            <app-select label="Category Name" [itemsList]="selectList"></app-select>
            <app-select label="Category Name" [itemsList]="selectList"></app-select>
            <app-select label="Category Name" [itemsList]="selectList"></app-select>
        </div> -->
        <div class="right">
            <div>
            <app-mat-condition *ngFor="let condition of queryData.conditionValues; let i = index" [condition]="condition" (updateCondition)="updateConditionValue($event, i)"></app-mat-condition>
            </div>
            <div class="btns">
            <div class="clear" (click)="clear()">Clear Form</div>
            <button class="white-button" (click)="search()">Search</button>
        </div>
        </div>
    </div>
</div>
