<header class="d-flex justify-content-between">
    <div class="d-flex flex-row align-items-center">
      <img class="menu" src="../../../assets/images/menu.png" alt="menu" (click)="manageMenu()"/>  
      <img  class="logo" src="../../../assets/images/icl.png" alt="icl-logo"/>  
     
    </div>
    <div class="d-flex align-items-center">
    <div class="d-flex flex-row align-items-center">
        <img class="menu" src="../../../assets/images/profile.png" alt="profile"/>   
        <div class="font-weight-bold">{{userName}}</div>
      </div>
      <div class="pipe"></div>
      <div class="d-flex align-items-center pointer" (click)="logout()">
        <span>Logout</span>
        <img class="logout" src="../../../assets/images/logout.png" alt="logout" />   
      </div>
    </div>
</header>
