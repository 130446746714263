import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Condition } from 'src/app/entities/Query';

@Component({
  selector: 'app-mat-condition',
  templateUrl: './mat-condition.component.html',
  styleUrls: ['./mat-condition.component.scss']
})
export class MatConditionComponent implements OnInit {
  selectList = ['aaa', 'bbbb', 'cccc'];
  @Output() updateCondition = new EventEmitter<Condition>();
  @Input() condition: Condition;
  constructor() { }
  ngOnInit(): void {
    //this.condition.operation = this.operation;
  }
  inputchange(event){
    this.condition.value =event.target.value;
    if(this.condition.name  && this.condition.value){
      this.updateCondition.emit(this.condition)
    }
  }
  changeSelectValue(value){
    this.condition.name = value;
    if(this.condition.name != '' && this.condition.value){
      this.updateCondition.emit(this.condition)
    }
  }
}

