import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscriber } from 'rxjs';
import { QueryData } from 'src/app/entities/Query';
import { QueryService } from 'src/app/services/query.service';


@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit, OnDestroy {
  quertData:QueryData;
  subscription = new Subscriber()
  @Output() queryEvent = new EventEmitter<QueryData>();


  constructor(private router: Router, private queryService: QueryService) {
  //   this.queryService.clearEvent$.subscribe(data=>{
  //     this.clear();
  //  });
  }

  ngOnInit(): void {
    this.subscription.add(this.queryService.queryData$.subscribe(quertData => {
      this.quertData = quertData;
    }))
  //  this.queryService.resetQuery();
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
  }

  advancedSearch(){
    this.router.navigate(['query']);
  }
  clear(){
    this.queryService.resetQuery();
    // this.quertData.queryValues.forEach(query => {
    //   query.value = '';
    // });
    // this.quertData.to = null;
    // this.quertData.from = null;
  }
  valuechange(){
    //this.queryService.queryData = this.quertData;
    this.queryService.setQueryData(this.quertData);
  }
  search(){
    //this.queryService.queryData = this.quertData;
    this.queryService.setQueryData(this.quertData)
   this.queryEvent.emit(this.quertData);
    
  }

}
