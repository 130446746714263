import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortAray, Table } from 'src/app/components/table/table.component';
import { ICLProduct } from 'src/app/entities/ICLProduct';
import { SdkService } from 'src/app/services/sdk.service';
import {select, lt, gt, eq, like} from 'sql-bricks';
import { QueryService } from 'src/app/services/query.service';
import { Subscriber } from 'rxjs';
import { Operation, QueryData } from 'src/app/entities/Query';
import { AdalService } from 'adal-angular4';


@Component({
  selector: 'app-query-results',
  templateUrl: './query-results.component.html',
  styleUrls: ['./query-results.component.scss']
})
export class QueryResultsComponent implements OnInit, OnDestroy {

  queryData: QueryData;
  subscription = new Subscriber()
  getData: string;
  showLoader: boolean;
  showPopup: boolean;
  textError: string;
  showEmptyResult: boolean = false;
  constructor(private router: Router, private sdkService: SdkService, private queryService: QueryService,  private route: ActivatedRoute, private adal: AdalService) { }
  tableData: Table<ICLProduct> = {
    type: "trucks",
    headlines: [
      { text: "Product Code", field: "code", maxLength: 10 },
      { text: "Brand Name", field: "brand", maxLength: 35 },
      { text: "Name", field: "name", maxLength: 20 },
      { text: "Product Type", field:"productType", maxLength: 15 },
      { text: "Amount", field: "amount", maxLength: 11 },
      { text: "Nolecule relation", field: "noleculeRelation", maxLength: 80 },
      { text: "The EC effect", field: "ecEffect", maxLength: 80 },
      { text: "Boron", field: "phEffect", maxLength: 80 },
      { text: "product families", field: "productFamilies", maxLength: 80 },

    ],
    data: [],
    rowButton: [],
  };
  ngOnInit(): void {
    console.log("azure token")
    console.log(this.adal.userInfo.token)
    //set token sdk
    var x  = this.adal.userInfo;
    this.sdkService.setToken(this.adal.userInfo.token);
     this.getData = this.route.snapshot.queryParams["getData"];
  
   // this.getProducts();
   if(this.getData !="true")
   this.createQuery();
   this.subscription.add(this.queryService.queryData$.subscribe(queryData => {
    this.queryData = queryData;
       if( this.getData=="true"){
       this.createQuery();
       this.getData = "false";
     }
    
  }))
  
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
  }
  clickRow(productDetails){
    this.router.navigate(['product'], { queryParams: { id: productDetails.code } });
  }
  sortArray(sortObject: SortAray){
    this.tableData.data.sort(function(a, b){
      var nameA=a[sortObject.field].toLowerCase(), nameB=b[sortObject.field].toLowerCase();
      if(sortObject.direction == 'top'){
      if (nameA < nameB) 
       return -1;
      if (nameA > nameB)
       return 1;
      return 0;
      } else {
        if (nameA < nameB) 
        return 1;
       if (nameA > nameB)
        return -1;
       return 0; 
      }
     });
  }

  async getProducts(){
    const  res = await this.sdkService.getProducts();
    this.tableData.data = res.map(e => {
      return new ICLProduct(e);
    });
  }
 async createQuery(){
   try{ 

    this.showEmptyResult = false;
  let querySelect = null;
   if(this.queryData && this.queryData.queryValues){
   let index = 0;
   const query = this.queryData;
   while(query && index < query.queryValues.length && query.queryValues[index].value == ''    ){
     index++
   }
   if(index < query.queryValues.length ){ 
    querySelect = select('*').from().where( like(query.queryValues[index].field, query.queryValues[index].value + "%"));
    for (let i = index +1; i < query.queryValues.length; i++) {
      if(query.queryValues[i].value && query.queryValues[i].value != ''){
      querySelect.and(like(query.queryValues[i].field, query.queryValues[i].value+ "%"));
      }
      
    }
  }
  if(querySelect){
    for(let i = 0; i < query.conditionValues.length; i++){
      if(query.conditionValues[i].value && query.conditionValues[i].name){
        if(query.conditionValues[i].operation == Operation.lt){
        querySelect.and(lt(query.conditionValues[i].name, query.conditionValues[i].value));
        } else  if(query.conditionValues[i].operation == Operation.gt){
          querySelect.and(gt(query.conditionValues[i].name, query.conditionValues[i].value));
          } else  if(query.conditionValues[i].operation == Operation.eq){
            querySelect.and(eq(query.conditionValues[i].name, query.conditionValues[i].value));
            }
      }
    }
  
  } else{
    index = 0;
    while(query && index <  query.conditionValues.length && (!query.conditionValues[index].value || !query.conditionValues[index].name)){
      index++
    }
    if(index < query.conditionValues.length ){
      if(query.conditionValues[index].operation == Operation.lt){
        querySelect = select('*').from().where(lt(query.conditionValues[index].name, query.conditionValues[index].value));
      } if(query.conditionValues[index].operation == Operation.gt){
        querySelect = select('*').from().where(gt(query.conditionValues[index].name, query.conditionValues[index].value));
      } if(query.conditionValues[index].operation == Operation.eq){
        querySelect = select('*').from().where(eq(query.conditionValues[index].name, query.conditionValues[index].value));
      }
    for (let i = index +1; i < query.conditionValues.length; i++) {
      if(query.conditionValues[i].value && query.conditionValues[i].name){
        if(query.conditionValues[i].operation == Operation.lt){
        querySelect.and(lt(query.conditionValues[i].name, query.conditionValues[i].value));
        } else  if(query.conditionValues[i].operation == Operation.gt){
          querySelect.and(gt(query.conditionValues[i].name, query.conditionValues[i].value));
          } else  if(query.conditionValues[i].operation == Operation.eq){
            querySelect.and(eq(query.conditionValues[i].name, query.conditionValues[i].value));
            }
      }
      }
      
    }
  }

}
if(!querySelect){
  querySelect = select('*').from();
}
console.log(querySelect.toString())
try {
  

this.showLoader = true;
const  res = await this.sdkService.search(querySelect);
this.showLoader = false;
if(res){
  if(res &&  res.length == 0){
    this.showEmptyResult = true;
  }
this.tableData.data = res.map(e => {
  return new ICLProduct(e);
});
}}catch (error) {
  this.showLoader = false;
  this.showPopup = true;
  this.textError = error;
  console.log('error http')
  console.error(error);
}
   } catch (err) {
    // this.showLoader = false;
    // this.showPopup = true;
    // this.textError = err;
    console.log('error global')
    console.error(err);
  }
}
close(){
  this.showPopup = false;
  this.textError = "";
}
}
