import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(private router: Router, private location: Location) { 
    router.events.subscribe((val) => {
     if(val instanceof NavigationEnd){
       this.url =val.url.substring(1)
     } 
  });
  }
  @Output() closeMenuEvent = new EventEmitter<string>();
  menuCategory: MenuCategory[] =[
    {text: 'Search', route: 'searchResults'},
   // {text: 'Advanced Search', route: 'query'}
  ];
     url: string;
  ngOnInit(): void {
       this.url =this.location.path().substring(1);
  }
  changeUrl(category:MenuCategory){
    this.router.navigate([category.route]);
    this.closeMenuEvent.emit();
  }

}
export class MenuCategory {
  text: string;
  route: string;
}
 